.swal-text{
  text-align: center !important;
  align-items: center !important;
}
.align-center{
  text-align: center !important;
  align-items: center !important;
}

.p-5{
  padding: 5px !important;
}
.p-15{
  padding: 15px !important;
}
.p-30{
  padding: 30px !important;
}
.pl-5{
  padding-left: 5px !important;
}
.pr-5{
  padding-right: 5px !important;
}
.pt-5{
  padding-top: 5px !important;
}
.pb-5{
  padding-bottom: 5px !important;
}
.pb-15{
  padding-bottom: 15px !important;
}


.m-5{
  margin: 5px !important;
}
.ml-5{
  margin-left: 5px !important;
}
.mr-5{
  margin-right: 5px !important;
}
.mt-5{
  margin-top: 5px !important;
}
.mb-5{
  margin-bottom: 5px !important;
}

.p-2{
  padding: 2px !important;
}
.pl-2{
  padding-left: 2px !important;
}
.pr-2{
  padding-right: 2px !important;
}
.pt-2{
  padding-top: 2px !important;
}
.pb-2{
  padding-bottom: 2px !important;
}


.m-2{
  margin: 2px !important;
}
.ml-2{
  margin-left: 2px !important;
}

.mr-2{
  margin-right: 2px !important;
}
.mt-2{
  margin-top: 2px !important;
}
.mb-2{
  margin-bottom: 2px !important;
}

.m-15{
  margin: 15px !important;
}
.ml-15{
  margin-left: 15px !important;
}
.mr-15{
  margin-right: 15px !important;
}
.mt-15{
  margin-top: 15px !important;
}

.p-3{
  padding: 3px !important;
}
.pl-3{
  padding-left: 3px !important;
}
.pr-3{
  padding-right: 3px !important;
}
.pt-3{
  padding-top: 3px !important;
}
.pb-3{
  padding-bottom: 3px !important;
}


.m-3{
  margin: 3px !important;
}
.ml-3{
  margin-left: 3px !important;
}
.mr-3{
  margin-right: 3px !important;
}
.mt-3{
  margin-top: 3px !important;
}
.mb-3{
  margin-bottom: 3px !important;
}

.m-15{
  margin: 15px !important;
}
.ml-15{
  margin-left: 15px !important;
}
.mr-15{
  margin-right: 15px !important;
}
.mt-15{
  margin-top: 15px !important;
}
.mt-100{
  margin-top: 100px !important;
}
.mb-100{
  margin-bottom: 100px !important;
}
.mb-15{
  margin-bottom: 15px !important;
}
.mb-30{
  margin-bottom: 30px !important;
}

.text-5{
  font-size: 20px !important;
}
.text-10{
  font-size: 20px !important;
}
.text-20{
  font-size: 20px !important;
}

.text-30{
  font-size: 30px !important;
}

.text-50{
  font-size: 50px;
}

.text-100{
  font-size: 100px;
}

.imw50{
  width: 50px;
}
.imh50{
  height: 50px;
}
.imw200{
  width: 200px;
}
.imh200{
  height: 200px;
}

.container {
  margin-bottom: 100px !important;
}

.ml--5{
  margin-left: -5px !important;
}

[data-id="tooltip"]{
  z-index: 1000 !important;
  /* position:relative !important; */
}

.noHover:hover{
  color: black !important;
}