/* -----------------------------------------
[Master Stylesheet]

Template Name: Suha - Multipurpose Ecommerce Mobile Template
Template Author: Designing World
Template Author URL: https://themeforest.net/user/designing-world/portfolio
Version: 1.3.0
Last Updated: 6 Apr 2020
Created: 15 Dec 2019

[Table of Contents]

    * Google Fonts
    * Include Third Party CSS Library
        + Bootstrap CSS
        + Animate CSS
        + Owl Carousel CSS
        + Magnific Popup CSS
        + Animated Headline CSS
        + Font Awesome CSS
        + Line Icon CSS
    * Template Mixins
        + Flex
        + Miscellaneous
    * Template Variables
    * Template Responsive
    * Main Styles
        + Reboot CSS
        + Shortcode CSS
        + Preloader CSS
        + Demo CSS
        + Header CSS
        + Hero CSS
        + Home CSS
        + Footer CSS
        + CTA CSS
        + Register CSS
        + Contact CSS
        + Dark Mode CSS

# [font-family]
'Roboto', sans-serif;
----------------------------------------- */
/* Import Fonts & All CSS Files */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&amp;display=swap");
@import url(css/bootstrap.min.css);
@import url(css/animate.css);
@import url(css/owl.carousel.min.css);
@import url(css/jquery.animatedheadline.css);
@import url(css/font-awesome.min.css);
@import url(css/default/lineicons.min.css);
/* Reboot CSS */
* {
  margin: 0;
  padding: 0; }

body,
html {
  font-family: "Roboto", sans-serif;
  color: #747794;
  overflow-x: hidden;
  background-color: #e7eaf5;
  -webkit-transition-duration: 500ms;
  -o-transition-duration: 500ms;
  transition-duration: 500ms; }

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #020310;
  line-height: 1.4;
  font-weight: 700; }

p {
  color: #747794;
  font-size: 14px; }

a,
a:hover,
a:focus {
  -webkit-transition-duration: 500ms;
  -o-transition-duration: 500ms;
  transition-duration: 500ms;
  text-decoration: none;
  outline: 0 solid transparent;
  box-shadow: none;
  color: #100DD1; }

.btn:focus {
  box-shadow: none; }

ul {
  margin: 0; }
  ul li {
    list-style: none;
    text-decoration: none; }
    ul li:hover, ul li:focus {
      list-style: none;
      text-decoration: none; }

ol {
  margin: 0; }

img {
  max-width: 100%;
  height: auto; }

/* Section Padding */
.section-padding-30 {
  padding-top: 30px;
  padding-bottom: 30px; }

.section-padding-0-30 {
  padding-top: 0;
  padding-bottom: 30px; }

.section-padding-30-0 {
  padding-top: 30px;
  padding-bottom: 0; }

.section-padding-30-14 {
  padding-top: 30px;
  padding-bottom: 14px; }

/* Scrollup */
#scrollUp {
  bottom: 30px;
  font-size: 16px;
  right: 30px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  color: #ffffff;
  text-align: center;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition-duration: 500ms;
  -o-transition-duration: 500ms;
  transition-duration: 500ms;
  border-radius: 6px;
  background-color: #020310; }
  #scrollUp:hover, #scrollUp:focus {
    background-color: #100DD1; }

.jarallax {
  position: relative;
  z-index: 0; }
  .jarallax .jarallax-img {
    position: absolute;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; }

input:required,
textarea:required {
  box-shadow: none !important; }

input:invalid,
textarea:invalid {
  box-shadow: none !important; }

.no-boxshadow {
  box-shadow: none !important; }

/* Shortcodes CSS */
.h-100vh {
  height: 100vh !important; }

.bg-img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }

.bg-fixed {
  background-attachment: fixed; }

.bg-overlay {
  position: relative;
  z-index: 1; }
  .bg-overlay::after {
    position: absolute;
    content: "";
    background: #100DD1;
    background: -webkit-linear-gradient(to left, #100DD1, #0862fb);
    background: linear-gradient(to left, #100DD1, #0862fb);
    opacity: 0.85;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1; }

.bg-gray {
  background-color: #f8f8ff; }

.btn,
.btn:hover,
.btn:focus {
  font-weight: 700;
  box-shadow: none; }

.btn {
  padding: .375rem 1rem;
  font-size: 0.85rem;
  border-radius: .4rem; }
  .btn:hover, .btn:focus {
    color: #ffffff; }

.btn-group-lg > .btn,
.btn-lg {
  padding: .75rem 1.25rem;
  font-size: 1rem;
  border-radius: .5rem; }

.btn-group-sm > .btn,
.btn-sm {
  padding: .25rem .75rem;
  font-size: .75rem;
  border-radius: .25rem; }

.btn-warning {
  color: #020310;
  background-color: #ffaf00;
  border-color: #ffaf00; }
  .btn-warning:hover, .btn-warning:focus {
    color: #ffffff;
    background-color: #020310;
    border-color: #020310; }

.btn-danger {
  background-color: #ea4c62;
  border-color: #ea4c62; }
  .btn-danger:hover, .btn-danger:focus {
    background-color: #020310;
    border-color: #020310; }

.btn-primary {
  background-color: #100DD1;
  border-color: #100DD1; }
  .btn-primary:hover, .btn-primary:focus {
    background-color: #020310;
    border-color: #020310; }

.btn-success {
  background-color: #00b894;
  border-color: #00b894; }
  .btn-success:hover, .btn-success:focus {
    background-color: #020310;
    border-color: #020310; }

.btn-info {
  background-color: #21d6f1;
  border-color: #21d6f1; }
  .btn-info:hover, .btn-info:focus {
    background-color: #020310;
    border-color: #020310; }

.bg-primary,
.badge-primary {
  background-color: #100DD1 !important; }

.bg-danger,
.badge-danger {
  background-color: #ea4c62 !important; }

.bg-warning,
.badge-warning {
  background-color: #ffaf00 !important; }

.bg-info,
.badge-info {
  background-color: #21d6f1 !important; }

.bg-success,
.badge-success {
  background-color: #00b894 !important; }

.text-dark {
  color: #020310 !important; }

.section-heading {
  position: relative;
  z-index: 1;
  margin-bottom: 1rem; }
  .section-heading h6 {
    margin-bottom: 0;
    color: #747794; }
  .section-heading p {
    margin-bottom: 0; }

.card {
  border-color: #ebebeb;
  border-radius: 0.5rem; }
  .card .card-body {
    padding: 0.75rem; }

.card-img-top {
  border-top-left-radius: calc(.75rem - 1px);
  border-top-right-radius: calc(.75rem - 1px); }

.border,
.border-left,
.border-right,
.border-bottom,
.border-top {
  border-color: #ebebeb !important; }

.mfp-iframe-holder .mfp-content {
  max-width: 1100px; }

.row {
  margin-right: -8px;
  margin-left: -8px; }

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-right: 8px;
  padding-left: 8px; }

/* Preloader CSS */
#preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  top: 0;
  left: 0;
  background-color: #f8f8ff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden; }

/* Demo CSS */
.preview-iframe-wrapper {
  position: relative;
  z-index: 1; }
  .preview-iframe-wrapper .preview-hero-area {
    position: relative;
    z-index: 1;
    width: 100%;
    padding-top: 100px;
    margin-bottom: -50px; }
    .preview-iframe-wrapper .preview-hero-area::after {
      position: absolute;
      content: "";
      background-image: url(img/core-img/curve.png);
      bottom: 50px;
      width: 142px;
      height: 121px;
      background-repeat: no-repeat;
      right: 0;
      z-index: auto; }
    .preview-iframe-wrapper .preview-hero-area .preview-content-wrapper {
      position: relative;
      z-index: 1;
      margin-bottom: 150px; }
      .preview-iframe-wrapper .preview-hero-area .preview-content-wrapper .demo-title {
        font-size: 2.1rem;
        margin-bottom: 1rem; }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          .preview-iframe-wrapper .preview-hero-area .preview-content-wrapper .demo-title {
            font-size: 1.75rem; } }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          .preview-iframe-wrapper .preview-hero-area .preview-content-wrapper .demo-title {
            font-size: 1.25rem; } }
        @media only screen and (max-width: 767px) {
          .preview-iframe-wrapper .preview-hero-area .preview-content-wrapper .demo-title {
            font-size: 1.5rem; } }
        @media only screen and (min-width: 576px) and (max-width: 767px) {
          .preview-iframe-wrapper .preview-hero-area .preview-content-wrapper .demo-title {
            font-size: 2rem; } }
        .preview-iframe-wrapper .preview-hero-area .preview-content-wrapper .demo-title span {
          color: #100DD1; }
      .preview-iframe-wrapper .preview-hero-area .preview-content-wrapper .demo-desc {
        font-size: 18px; }
      .preview-iframe-wrapper .preview-hero-area .preview-content-wrapper .qr-code-wrapper {
        position: relative;
        z-index: 1;
        background-color: #ffffff;
        text-align: center;
        max-width: 18rem;
        padding: 2rem;
        border-radius: .5rem; }
        .preview-iframe-wrapper .preview-hero-area .preview-content-wrapper .qr-code-wrapper::after {
          position: absolute;
          width: 200px;
          height: 200px;
          background-image: url(img/core-img/dot-blue.png);
          background-repeat: repeat;
          bottom: -70px;
          left: -70px;
          z-index: -10;
          content: "";
          border-radius: 50%;
          opacity: 0.15; }
        .preview-iframe-wrapper .preview-hero-area .preview-content-wrapper .qr-code-wrapper h6 {
          font-size: 14px; }
    .preview-iframe-wrapper .preview-hero-area iframe {
      width: 385px;
      height: 748px;
      border: 5px solid #272727;
      border-radius: 1.25rem;
      box-shadow: 0 2px 2px 2px rgba(14, 14, 14, 0.125);
      margin-bottom: 150px; }
      @media only screen and (max-width: 767px) {
        .preview-iframe-wrapper .preview-hero-area iframe {
          width: 100%; } }
  .preview-iframe-wrapper .tech-img ul li {
    margin-right: 1rem; }
    .preview-iframe-wrapper .tech-img ul li:last-child {
      margin-right: 0rem; }
    .preview-iframe-wrapper .tech-img ul li img {
      max-height: 28px; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .preview-iframe-wrapper .tech-img ul li img {
          max-height: 22px; } }
      @media only screen and (max-width: 767px) {
        .preview-iframe-wrapper .tech-img ul li img {
          max-height: 22px; } }
  .preview-iframe-wrapper .features-area {
    position: relative;
    z-index: 1;
    padding: 100px 0;
    border-radius: 3rem 0 0 0; }
    .preview-iframe-wrapper .features-area ul li {
      position: relative;
      z-index: 1;
      padding-left: 1.5rem;
      margin-top: 1rem; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .preview-iframe-wrapper .features-area ul li {
          font-size: 14px; } }
      @media only screen and (max-width: 767px) {
        .preview-iframe-wrapper .features-area ul li {
          font-size: 14px; } }
      .preview-iframe-wrapper .features-area ul li.active {
        color: #020310; }
      .preview-iframe-wrapper .features-area ul li::before {
        position: absolute;
        content: "\f00c";
        top: 0;
        left: 0;
        font-family: "FontAwesome";
        font-size: 16px;
        color: #100DD1;
        z-index: 1; }

.preview-footer-area {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100px; }
  .preview-footer-area .footer-nav a {
    margin-left: 1rem;
    font-size: 14px;
    color: #747794;
    font-weight: 700; }
    .preview-footer-area .footer-nav a:hover, .preview-footer-area .footer-nav a:focus {
      color: #100DD1; }
  .preview-footer-area .footer-logo img {
    max-height: 50px; }

.header-area {
  -webkit-transition-duration: 500ms;
  -o-transition-duration: 500ms;
  transition-duration: 500ms;
  background-color: #ffffff;
  width: 100%;
  height: 50px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  box-shadow: 0 2px 12px rgba(15, 15, 15, 0.175); }
  .header-area.header-out {
    top: -100px; }

.top-search-form {
  position: relative;
  z-index: 1; }
  .top-search-form form {
    position: relative;
    z-index: 1; }
    .top-search-form form .form-control {
      max-width: 190px;
      background-color: #ffffff;
      height: 32px;
      font-size: 12px;
      padding: 10px 15px;
      padding-left: 35px;
      border: 1px solid #ebebeb;
      color: #747794; }
    .top-search-form form button {
      background-color: transparent;
      position: absolute;
      top: 1px;
      left: 10px;
      width: 18px;
      height: 30px;
      border: 0;
      z-index: 10;
      color: #747794; }
      .top-search-form form button:focus {
        outline: none; }

.suha-navbar-toggler {
  position: relative;
  z-index: 1;
  width: 26px;
  cursor: pointer;
  padding: .5rem 0; }
  .suha-navbar-toggler span {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 22px;
    flex: 0 0 22px;
    min-width: 22px;
    width: 22px;
    height: 2px;
    background-color: #747794;
    margin-bottom: 4px; }
    .suha-navbar-toggler span:nth-child(2) {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 12px;
      flex: 0 0 12px;
      min-width: 12px;
      width: 12px; }
    .suha-navbar-toggler span:last-child {
      margin-bottom: 0;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 17px;
      flex: 0 0 17px;
      min-width: 17px;
      width: 17px; }
  .suha-navbar-toggler:hover span, .suha-navbar-toggler:focus span {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 22px;
    flex: 0 0 22px;
    min-width: 22px;
    width: 22px; }

.filter-option {
  position: relative;
  z-index: 1;
  cursor: pointer;
  text-align: right;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 20px; }

.back-button a {
  color: #747794;
  font-weight: 500;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .back-button a i {
    font-size: 1.25rem;
    margin-right: 0.25rem; }
  .back-button a:hover, .back-button a:focus {
    color: #100DD1; }

.page-heading h6 {
  color: #747794;
  font-size: 14px; }

.logo-wrapper a {
  display: block; }

/* Hero CSS */
.intro-wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  background: #100DD1;
  background: -webkit-linear-gradient(to left, #100DD1, #0862fb);
  background: linear-gradient(to left, #100DD1, #0862fb);
  z-index: 1; }
  .intro-wrapper .background-shape {
    position: absolute;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background-color: #ffffff;
    opacity: 0.1;
    z-index: -1;
    top: -100px;
    right: -60px; }

.get-started-btn {
  position: fixed;
  z-index: 10;
  bottom: 40px;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px; }

.page-content-wrapper {
  position: relative;
  z-index: 1;
  margin-top: 50px;
  margin-bottom: 58px; }

.suha-sidenav-wrapper {
  -webkit-transition-duration: 500ms;
  -o-transition-duration: 500ms;
  transition-duration: 500ms;
  position: fixed;
  width: 100%;
  z-index: 999999;
  top: 0;
  left: -250px;
  height: 100%;
  background: #100DD1;
  background: -webkit-linear-gradient(to left, #100DD1, #0862fb);
  background: linear-gradient(to left, #100DD1, #0862fb);
  overflow-y: auto;
  width: 240px; }
  .suha-sidenav-wrapper .go-home-btn {
    -webkit-transition-duration: 400ms;
    -o-transition-duration: 400ms;
    transition-duration: 400ms;
    position: fixed;
    bottom: 30px;
    left: 170px;
    background-color: #00b894;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    color: #ffffff;
    line-height: 42px;
    z-index: 10;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    font-size: 1.25rem; }
    .suha-sidenav-wrapper .go-home-btn:hover, .suha-sidenav-wrapper .go-home-btn:focus {
      background-color: #020310; }
  .suha-sidenav-wrapper.nav-active {
    left: 0; }
    .suha-sidenav-wrapper.nav-active .go-home-btn {
      visibility: visible;
      opacity: 1; }
  .suha-sidenav-wrapper.filter-nav {
    background: #f8f8ff; }

.sidenav-black-overlay {
  -webkit-transition-duration: 500ms;
  -o-transition-duration: 500ms;
  transition-duration: 500ms;
  width: 100%;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(2, 3, 16, 0.3);
  z-index: 9999;
  opacity: 0;
  visibility: hidden; }
  .sidenav-black-overlay.active {
    opacity: 1;
    visibility: visible; }

.sidenav-profile {
  position: relative;
  z-index: 1; }
  .sidenav-profile .user-profile {
    position: relative;
    z-index: 1;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: 3rem auto 0.5rem; }
    .sidenav-profile .user-profile::before {
      position: absolute;
      width: 110px;
      height: 110px;
      top: -15px;
      left: -15px;
      background-image: url(img/core-img/dot.png);
      content: "";
      z-index: -10;
      opacity: 0.2;
      border-radius: 50%; }
    .sidenav-profile .user-profile img {
      border-radius: 50%; }
    .sidenav-profile .user-profile .user-designation {
      position: absolute;
      z-index: 1;
      height: 46px;
      width: 46px;
      font-size: 12px;
      border: 3px solid #ffffff;
      background-color: #100DD1;
      display: inline-block;
      border-radius: 50%;
      color: #ffffff;
      line-height: 40px;
      text-align: center;
      font-weight: 700;
      top: 0;
      right: 0; }
  .sidenav-profile .user-info {
    position: relative;
    z-index: 1;
    text-align: center; }
    .sidenav-profile .user-info h6,
    .sidenav-profile .user-info p {
      color: #ffffff; }
    .sidenav-profile .user-info .available-balance {
      margin-bottom: 0;
      display: block;
      font-size: 14px;
      font-weight: 700;
      color: #ffffff; }

.sidenav-nav {
  margin: 3rem 1rem 1rem;
  position: relative;
  z-index: 1; }
  .sidenav-nav li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #ffffff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-right: 1rem;
    padding-left: 1rem;
    font-size: 14px;
    font-weight: 700; }
    .sidenav-nav li a i {
      -webkit-transition-duration: 500ms;
      -o-transition-duration: 500ms;
      transition-duration: 500ms;
      display: inline-block;
      margin-right: 0.5rem;
      font-size: 1.25rem;
      color: #ffffff; }
    .sidenav-nav li a:hover, .sidenav-nav li a:focus {
      background-color: rgba(15, 15, 15, 0.1); }
  .sidenav-nav li:first-child a {
    border-top: 1px solid rgba(255, 255, 255, 0.1); }
  .sidenav-nav li:last-child a {
    margin-bottom: 0; }

.page-nav {
  position: relative;
  z-index: 1; }
  .page-nav li a {
    color: #020310;
    display: block;
    padding: 0.75rem 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0.5rem;
    border-radius: 0.5rem;
    background-color: #ffffff;
    font-size: 14px;
    font-weight: 500; }
    .page-nav li a i {
      font-size: 1rem;
      margin-left: auto; }
    .page-nav li a:hover, .page-nav li a:focus {
      color: #100DD1;
      background-color: #f8f8ff; }

.hero-slides {
  position: relative;
  z-index: 1; }
  .hero-slides .owl-prev,
  .hero-slides .owl-next {
    position: absolute;
    top: 50%;
    background-color: rgba(255, 255, 255, 0.11);
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    left: 0;
    margin-top: -15px;
    color: #ffffff;
    display: none; }
  .hero-slides .owl-next {
    left: auto;
    right: 0; }
  .hero-slides .owl-dots {
    position: absolute;
    width: 100%;
    bottom: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
    .hero-slides .owl-dots .owl-dot {
      -webkit-transition-duration: 500ms;
      -o-transition-duration: 500ms;
      transition-duration: 500ms;
      position: relative;
      z-index: 1;
      margin: 0 5px;
      width: 8px;
      height: 8px;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 8px;
      flex: 0 0 8px;
      max-width: 8px;
      background-color: #ebebeb;
      border-radius: 50%; }
      .hero-slides .owl-dots .owl-dot.active {
        background-color: #ffaf00; }
        .hero-slides .owl-dots .owl-dot.active::after {
          position: absolute;
          width: calc(100% + 8px);
          height: calc(100% + 8px);
          z-index: 1;
          content: "";
          border: 2px solid #ffaf00;
          left: -4px;
          top: -4px;
          border-radius: 50%; }

.single-hero-slide {
  position: relative;
  z-index: 1;
  width: 100%; }
  .single-hero-slide .slide-content {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10; }

.catagory-card {
  position: relative;
  z-index: 1;
  text-align: center; }
  .catagory-card a {
    display: block;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; }
  .catagory-card i {
    margin-bottom: 0.75rem;
    font-size: 2rem;
    display: block; }
  .catagory-card span {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    display: block;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: #747794; }
  .catagory-card:hover span, .catagory-card:focus span {
    color: #100DD1; }

.subcatagory-card {
  position: relative;
  z-index: 1;
  text-align: center; }
  .subcatagory-card a {
    display: block; }
  .subcatagory-card img {
    margin-bottom: 0.6rem;
    max-height: 42px; }
  .subcatagory-card span {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    display: block;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: #747794; }
  .subcatagory-card:hover span, .subcatagory-card:focus span {
    color: #100DD1; }

.flash-sale-slide.owl-carousel {
  width: 130% !important; }

.flash-sale-card {
  position: relative;
  z-index: 1; }
  .flash-sale-card img {
    margin-bottom: 0.5rem; }
  .flash-sale-card .product-title {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    color: #020310;
    font-size: 14px;
    font-weight: 500;
    display: block;
    line-height: 1.2;
    margin-bottom: 0.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .flash-sale-card .sale-price {
    font-size: 14px;
    font-weight: 700;
    color: #100DD1;
    margin-bottom: 0; }
  .flash-sale-card .real-price {
    display: inline-block;
    margin-left: 5px;
    font-size: 12px;
    text-decoration: line-through;
    margin-bottom: 0.25rem; }
  .flash-sale-card .progress {
    height: 0.25rem; }
  .flash-sale-card .progress-title {
    color: #747794;
    font-size: 12px;
    display: block; }
  .flash-sale-card:hover .product-title, .flash-sale-card:focus .product-title {
    color: #100DD1; }

.top-product-card {
  position: relative;
  z-index: 1; }
  .top-product-card .badge {
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 10; }
  .top-product-card .wishlist-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 10;
    color: #ea4c62;
    font-size: 1.25rem;
    line-height: 1; }
  .top-product-card .product-thumbnail img {
    width: 100%; }
  .top-product-card .delete-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 10;
    color: #747794;
    font-size: 1.25rem;
    line-height: 1; }
  .top-product-card .product-title {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0.25rem;
    color: #020310; }
  .top-product-card .sale-price {
    margin-bottom: 0.25rem;
    color: #00b894;
    font-weight: 700; }
    .top-product-card .sale-price span {
      color: #747794;
      margin-left: 5px;
      text-decoration: line-through;
      font-size: 14px; }
  .top-product-card .product-rating {
    font-size: 10px;
    color: #ffaf00; }
    .top-product-card .product-rating i {
      margin: 0 1px; }
  .top-product-card .btn {
    padding: 0;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    position: absolute;
    right: 12px;
    bottom: 12px;
    font-size: 1rem;
    z-index: 9; }
  .top-product-card:hover .product-title, .top-product-card:focus .product-title {
    color: #100DD1; }

.weekly-product-card {
  position: relative;
  z-index: 1; }
  .weekly-product-card .product-thumbnail-side {
    position: relative;
    z-index: 1;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    width: 50%;
    padding-right: 1rem; }
    .weekly-product-card .product-thumbnail-side .product-thumbnail {
      position: relative;
      z-index: 1; }
      .weekly-product-card .product-thumbnail-side .product-thumbnail img {
        max-height: 300px; }
    .weekly-product-card .product-thumbnail-side .badge {
      position: absolute;
      z-index: 2;
      top: 0.5rem;
      left: 0.5rem; }
    .weekly-product-card .product-thumbnail-side .wishlist-btn {
      position: absolute;
      bottom: 0.5rem;
      left: 0.5rem;
      color: #ea4c62;
      line-height: 1;
      z-index: 11; }
    .weekly-product-card .product-thumbnail-side .delete-btn {
      position: absolute;
      bottom: 0.5rem;
      left: 0.5rem;
      color: #747794;
      line-height: 1;
      z-index: 11; }
  .weekly-product-card .product-description {
    position: relative;
    z-index: 1;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    width: 50%; }
    .weekly-product-card .product-description .product-title {
      margin-bottom: 0.5rem;
      font-weight: 700;
      font-size: 14px;
      color: #020310; }
    .weekly-product-card .product-description .sale-price {
      margin-bottom: 0.5rem;
      font-size: 14px;
      font-weight: 700;
      color: #020310; }
      .weekly-product-card .product-description .sale-price i {
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        color: #ffffff;
        background-color: #100DD1;
        font-size: 10px;
        text-align: center;
        line-height: 20px;
        margin-right: 0.25rem; }
      .weekly-product-card .product-description .sale-price span {
        text-decoration: line-through;
        color: #ea4c62;
        margin-left: 0.25rem;
        display: inline-block; }
    .weekly-product-card .product-description .product-rating {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 0.5rem; }
      .weekly-product-card .product-description .product-rating i {
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        color: #ffffff;
        background-color: #ffaf00;
        font-size: 10px;
        text-align: center;
        line-height: 20px;
        margin-right: 0.25rem;
        padding-left: 1px; }
    .weekly-product-card .product-description .btn i {
      font-size: 14px; }
  .weekly-product-card:hover .product-title, .weekly-product-card:focus .product-title {
    color: #100DD1; }

.catagory-sidebar-area {
  position: relative;
  z-index: 1;
  padding: 3rem 2rem; }

.widget .widget-title {
  display: block; }

.widget .custom-control-label {
  color: #747794; }
  .widget .custom-control-label::before {
    background-color: transparent;
    border: #747794 solid 2px; }

.widget .custom-control-input:checked ~ .custom-control-label::before {
  color: #ea4c62;
  border-color: #ea4c62;
  background-color: #ea4c62; }

.notification-area {
  position: relative;
  z-index: 1; }
  .notification-area .list-group-item {
    border-color: #ebebeb;
    margin-bottom: 0.5rem;
    border-radius: 0.5rem; }
    .notification-area .list-group-item .noti-icon {
      position: relative;
      z-index: 1;
      display: inline-block;
      width: 30px;
      height: 30px;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 30px;
      flex: 0 0 30px;
      max-width: 30px;
      background: #100DD1;
      background: -webkit-linear-gradient(to left, #100DD1, #0862fb);
      background: linear-gradient(to left, #100DD1, #0862fb);
      color: #ffffff;
      text-align: center;
      font-size: 1rem;
      margin-right: .75rem;
      border-radius: 50%; }
      .notification-area .list-group-item .noti-icon i {
        line-height: 30px; }
      .notification-area .list-group-item .noti-icon::before {
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: #ffaf00;
        content: "";
        top: -2px;
        right: -2px;
        border-radius: 50%;
        z-index: 1; }
    .notification-area .list-group-item h6 {
      font-size: 14px;
      -webkit-transition-duration: 500ms;
      -o-transition-duration: 500ms;
      transition-duration: 500ms;
      color: #020310; }
    .notification-area .list-group-item p {
      margin-bottom: 0.5rem; }
    .notification-area .list-group-item a {
      font-size: 14px; }
    .notification-area .list-group-item span {
      font-size: 12px;
      display: block;
      color: #747794; }
    .notification-area .list-group-item:first-child {
      border-top-left-radius: .5rem;
      border-top-right-radius: .5rem; }
    .notification-area .list-group-item:last-child {
      border-bottom-right-radius: .5rem;
      border-bottom-left-radius: .5rem; }
    .notification-area .list-group-item.readed {
      background-color: #f8f8ff; }
      .notification-area .list-group-item.readed .noti-icon {
        background: #00b894; }
        .notification-area .list-group-item.readed .noti-icon::before {
          display: none; }
      .notification-area .list-group-item.readed h6 {
        color: #747794; }
    .notification-area .list-group-item:hover h6, .notification-area .list-group-item:focus h6 {
      color: #100DD1; }
  .notification-area .unread-notifications {
    position: relative;
    z-index: 1; }

.layout-options {
  position: relative;
  z-index: 1; }
  .layout-options a {
    display: inline-block;
    width: 36px;
    height: 30px;
    background-color: #ffffff;
    border-radius: 0.25rem;
    margin-left: .5rem;
    text-align: center;
    font-size: 1rem;
    color: #747794; }
    .layout-options a i {
      line-height: 30px; }
    .layout-options a:hover, .layout-options a:focus, .layout-options a.active {
      color: #ffffff;
      background-color: #ea4c62; }

.settings-card {
  margin-bottom: 0.25rem; }
  .settings-card:last-child {
    margin-bottom: 0; }

.single-settings {
  position: relative;
  z-index: 1; }
  .single-settings .title {
    font-size: 14px; }
    .single-settings .title i {
      width: 22px;
      height: 22px;
      display: inline-block;
      background-color: #8A4821;
      text-align: center;
      color: #ffffff;
      line-height: 22px;
      border-radius: .25rem;
      margin-right: .5rem;
      font-size: 14px; }
    .single-settings .title span span {
      font-size: 10px;
      margin-left: 0.5rem; }
  .single-settings .data-content {
    font-size: 13px;
    font-weight: 700; }
    .single-settings .data-content a {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
    .single-settings .data-content i {
      margin-left: 0.25rem; }
  .single-settings .toggle-button-cover {
    position: relative;
    z-index: 1; }
  .single-settings .knobs,
  .single-settings .layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .single-settings .button.r {
    position: relative;
    width: 45px;
    height: 21px;
    overflow: hidden;
    border: 1px solid #ebebeb; }
  .single-settings .button.r,
  .single-settings .button.r .layer {
    border-radius: 100px; }
  .single-settings .checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3; }
  .single-settings .knobs {
    z-index: 2; }
  .single-settings .layer {
    width: 100%;
    background-color: transparent;
    transition: 0.3s ease all;
    z-index: 1; }
  .single-settings .knobs:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 15px;
    height: 15px;
    background-color: #ea4c62;
    border-radius: 50%;
    transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15); }
  .single-settings .checkbox:active + .knobs:before {
    width: 45px;
    border-radius: 100px; }
  .single-settings .checkbox:checked:active + .knobs:before {
    margin-left: -26px; }
  .single-settings .checkbox:checked + .knobs:before {
    content: '';
    left: 24px;
    background-color: #100DD1; }
  .single-settings .checkbox:checked ~ .layer {
    background-color: #f8f8ff; }

.language-area-wrapper,
.shipping-method-choose {
  position: relative; }
  .language-area-wrapper ul,
  .shipping-method-choose ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .language-area-wrapper ul li,
    .shipping-method-choose ul li {
      display: block;
      position: relative;
      z-index: 1;
      width: 100%; }
      .language-area-wrapper ul li input[type=radio],
      .shipping-method-choose ul li input[type=radio] {
        position: absolute;
        visibility: hidden; }
      .language-area-wrapper ul li label,
      .shipping-method-choose ul li label {
        display: block;
        position: relative;
        padding: 10px 10px 10px 45px;
        z-index: 9;
        cursor: pointer;
        transition: all 0.25s linear;
        margin-bottom: 0;
        border-radius: 0.4rem;
        font-size: 14px;
        font-weight: 700; }
        .language-area-wrapper ul li label span,
        .shipping-method-choose ul li label span {
          font-size: 11px;
          margin-left: 0.5rem; }
      .language-area-wrapper ul li .check,
      .shipping-method-choose ul li .check {
        display: block;
        position: absolute;
        border: 4px solid #00b894;
        border-radius: 100%;
        height: 20px;
        width: 20px;
        top: 50%;
        margin-top: -10px;
        left: 15px;
        z-index: 5;
        transition: all .25s linear; }
        .language-area-wrapper ul li .check::before,
        .shipping-method-choose ul li .check::before {
          display: block;
          position: absolute;
          content: '';
          border-radius: 100%;
          height: 8px;
          width: 8px;
          top: 50%;
          left: 50%;
          margin: -4px;
          transition: background 0.25s linear; }
  .language-area-wrapper input[type=radio]:checked ~ .check,
  .shipping-method-choose input[type=radio]:checked ~ .check {
    border-color: #100DD1; }
  .language-area-wrapper input[type=radio]:checked ~ .check::before,
  .shipping-method-choose input[type=radio]:checked ~ .check::before {
    background: #100DD1; }
  .language-area-wrapper input[type=radio]:checked ~ label,
  .shipping-method-choose input[type=radio]:checked ~ label {
    color: #100DD1;
    background-color: rgba(16, 13, 209, 0.103); }

.user-info-card {
  position: relative;
  z-index: 1;
  background-color: #ffaf00;
  border-color: #ffaf00;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }
  .user-info-card .user-profile {
    position: relative;
    z-index: 1;
    width: 80px;
    height: 80px;
    border-radius: 50%; }
    .user-info-card .user-profile img {
      border-radius: 50%; }

.single-profile-data {
  position: relative;
  z-index: 1;
  margin-bottom: 1.25rem; }
  .single-profile-data:last-child {
    margin-bottom: 0; }
  .single-profile-data .title {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
    font-size: 12px;
    color: #020310; }
    .single-profile-data .title i {
      width: 22px;
      height: 22px;
      display: inline-block;
      background-color: #8A4821;
      text-align: center;
      color: #ffffff;
      line-height: 23px;
      border-radius: .25rem;
      margin-right: .4rem;
      font-size: 12px; }
    .single-profile-data .title span {
      line-height: 1; }
  .single-profile-data .data-content {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
    font-size: 13px;
    text-align: right;
    color: #747794; }

.user-data-card {
  position: relative;
  z-index: 1;
  border-color: #ffffff;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }
  .user-data-card .card-body {
    padding: 1.25rem; }
  .user-data-card .title {
    font-size: 13px;
    color: #020310;
    font-weight: 500;
    margin-bottom: 0; }
    .user-data-card .title i {
      width: 24px;
      height: 24px;
      display: inline-block;
      background-color: #00b894;
      text-align: center;
      color: #ffffff;
      line-height: 24px;
      border-radius: .25rem;
      margin-right: .4rem;
      font-size: 14px; }
  .user-data-card .form-control {
    height: 36px;
    padding: 0 15px;
    color: #747794;
    font-size: 13px; }

.change-user-thumb {
  position: absolute;
  z-index: 1;
  width: 30px;
  height: 30px;
  bottom: 0;
  right: 0; }
  .change-user-thumb input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 10;
    opacity: 0; }
  .change-user-thumb button {
    width: 100%;
    height: 100%;
    background-color: #f8f8ff;
    border: none;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 14px;
    z-index: 1;
    border: 1px solid #ebebeb; }

.support-wrapper .faq-heading {
  background: #100DD1;
  background: -webkit-linear-gradient(to right, #100DD1, #00b894);
  background: linear-gradient(to right, #100DD1, #00b894);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800; }

.accordian-card {
  position: relative;
  z-index: 1; }
  .accordian-card .card-body {
    padding: 1.25rem; }
  .accordian-card p {
    padding-left: 35px; }
    .accordian-card p:last-child {
      margin-bottom: 0; }
  .accordian-card .accordian-header.collapse {
    display: block; }
  .accordian-card .accordian-header button {
    color: #100DD1;
    text-align: left;
    padding-left: 0;
    padding-right: 0; }
    .accordian-card .accordian-header button span i {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: #100DD1;
      display: inline-block;
      text-align: center;
      line-height: 30px;
      color: #ffffff;
      margin-right: 0.5rem;
      font-size: 1rem; }
    .accordian-card .accordian-header button > i {
      color: #747794;
      -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg); }
    .accordian-card .accordian-header button:hover, .accordian-card .accordian-header button:focus {
      color: #100DD1; }
    .accordian-card .accordian-header button.collapsed > span {
      color: #020310; }
    .accordian-card .accordian-header button.collapsed > i {
      -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
      transform: rotate(0); }
  .accordian-card.seller-card .accordian-header button span i {
    background-color: #ea4c62; }
  .accordian-card.others-card .accordian-header button span i {
    background-color: #00b894; }

.faq-search-form {
  position: relative;
  z-index: 1; }
  .faq-search-form input {
    border-radius: 60px;
    padding-left: 40px;
    font-weight: 700; }
    .faq-search-form input:focus {
      border-color: transparent; }
  .faq-search-form button {
    position: absolute;
    width: 50px;
    height: 50px;
    border: 0;
    background-color: transparent;
    text-align: center;
    top: 0;
    left: 0;
    z-index: 10; }

.live-chat-intro {
  position: relative;
  z-index: 1;
  background-color: #100DD1;
  padding: 2rem 0;
  text-align: center;
  border-radius: 0 0 3rem 3rem; }
  .live-chat-intro p {
    color: #ffffff;
    margin-bottom: 0.5rem; }
  .live-chat-intro img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 0 auto .5rem;
    display: block; }
  .live-chat-intro .status {
    position: relative;
    z-index: 1;
    color: #ffffff;
    font-size: 14px;
    display: inline-block; }
    .live-chat-intro .status.online {
      padding-left: 15px; }
      .live-chat-intro .status.online::after {
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: #00b894;
        border-radius: 50%;
        content: "";
        margin-top: -5px;
        top: 50%;
        left: 0;
        z-index: 10; }

.agent-message-content {
  position: relative;
  z-index: 1;
  margin-bottom: 1rem; }
  .agent-message-content .agent-thumbnail {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 30px;
    flex: 0 0 30px;
    max-width: 30px;
    width: 30px; }
    .agent-message-content .agent-thumbnail img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      box-shadow: 0 1px 3px 0 rgba(15, 15, 15, 0.175);
      background-color: #ffffff;
      padding: 2px; }
  .agent-message-content .agent-message-text {
    position: relative;
    z-index: 1; }
    .agent-message-content .agent-message-text span {
      font-size: 12px;
      color: #747794;
      margin-left: 0.5rem;
      margin-top: 0.25rem;
      display: block; }
    .agent-message-content .agent-message-text p {
      display: inline-block;
      margin-bottom: 0;
      background-color: #ffffff;
      padding: .75rem 1rem .65rem;
      border-radius: 1rem; }

.user-message-content {
  position: relative;
  z-index: 1;
  text-align: right;
  margin-bottom: 1rem; }
  .user-message-content .user-message-text {
    padding-left: 3rem; }
    .user-message-content .user-message-text p {
      display: inline-block;
      margin-bottom: 0;
      padding: .75rem 1rem .65rem;
      background-color: #100DD1;
      color: #ffffff;
      border-radius: 1rem; }
    .user-message-content .user-message-text span {
      font-size: 12px;
      display: block;
      color: #747794;
      margin-right: 0.5rem;
      margin-top: 0.25rem; }

.type-text-form {
  position: fixed;
  width: 100%;
  height: 55px;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  z-index: 99999; }
  .type-text-form .form-control {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    width: 100%;
    height: 55px;
    border-color: transparent;
    color: #747794;
    font-size: 13px;
    padding-top: 17px;
    padding-bottom: 17px;
    padding-left: 40px; }
    .type-text-form .form-control:focus {
      border-color: transparent; }
  .type-text-form button[type="submit"] {
    position: absolute;
    width: 40px;
    height: 55px;
    top: 0;
    right: 0;
    z-index: 30;
    border: 0;
    background-color: #ffffff;
    font-size: 1rem;
    color: #020310; }
  .type-text-form .file-upload {
    position: absolute;
    width: 40px;
    height: 55px;
    top: 0;
    left: 0;
    z-index: 20; }
    .type-text-form .file-upload input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 200;
      opacity: 0; }
    .type-text-form .file-upload span {
      position: absolute;
      top: 0;
      left: 0;
      background-color: #ffffff;
      height: 100%;
      width: 100%;
      z-index: 50;
      text-align: center;
      line-height: 55px; }

.single-order-status {
  position: relative;
  z-index: 1;
  margin-bottom: 1rem; }
  .single-order-status:last-child {
    margin-bottom: 0; }
  .single-order-status .order-icon {
    color: #100DD1;
    margin-right: .5rem; }
  .single-order-status .order-status {
    color: #020310;
    font-size: 14px;
    font-weight: 700; }
    .single-order-status .order-status .order-date {
      font-size: 12px;
      color: #747794;
      margin-left: 0.5rem; }

.cart-table {
  position: relative;
  z-index: 1; }
  .cart-table table {
    max-width: 100%; }
    .cart-table table tbody tr {
      background-color: #ffffff;
      border-bottom: 2px dashed #ebebeb; }
      .cart-table table tbody tr:last-child {
        border-bottom: 0; }
    .cart-table table a {
      color: #020310;
      font-weight: 700; }
      .cart-table table a.remove-product {
        color: #ffffff;
        width: 24px;
        height: 24px;
        background-color: #ea4c62;
        display: inline-block;
        text-align: center;
        line-height: 25px;
        border-radius: 50%;
        font-size: 10px; }
        .cart-table table a.remove-product:hover, .cart-table table a.remove-product:focus {
          color: #ffffff;
          background-color: #020310; }
      .cart-table table a span {
        display: block;
        color: #747794; }
      .cart-table table a:hover, .cart-table table a:focus {
        color: #100DD1; }
  .cart-table .table td,
  .cart-table .table th {
    vertical-align: middle;
    color: #747794;
    font-size: 12px;
    padding: 1rem .5rem;
    border: 0; }
  .cart-table img {
    max-width: 2.5rem;
    border: 1px solid #ebebeb; }
  .cart-table .qty-text {
    border: 1px solid #ebebeb;
    width: 2.5rem;
    text-align: center;
    height: 2rem;
    border-radius: .25rem;
    -webkit-transition-duration: 300ms;
    -o-transition-duration: 300ms;
    transition-duration: 300ms; }
    .cart-table .qty-text:focus {
      border: 1px solid #100DD1; }

.coupon-card .card-body {
  padding: 1.25rem; }

.cart-amount-area .card-body {
  padding: .75rem 1.25rem; }

.apply-coupon {
  position: relative;
  z-index: 1; }
  .apply-coupon p {
    font-size: 12px; }

.coupon-form {
  position: relative;
  z-index: 1; }
  .coupon-form .form-control {
    height: 34.4px;
    padding: 5px 15px;
    font-size: 12px;
    border-radius: .4rem; }
  .coupon-form button {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10; }

.billing-information-card {
  position: relative;
  z-index: 1; }

.billing-information-title-card {
  border-radius: .5rem .5rem 0 0;
  border-color: #ea4c62; }

.shipping-method-choose-title-card {
  border-radius: .5rem .5rem 0 0;
  border-color: #00b894; }

.shipping-method-choose-card {
  border-radius: 0 0 .5rem .5rem;
  border-color: #ffffff; }

.single-payment-method a {
  display: block;
  position: relative;
  z-index: 1;
  background-color: #ffffff;
  margin-bottom: 1rem;
  text-align: center;
  padding: 3rem 1rem;
  border-radius: 1rem; }
  .single-payment-method a i {
    font-size: 3rem;
    margin-bottom: .5rem;
    display: block; }
  .single-payment-method a h6 {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    margin-bottom: 0; }
  .single-payment-method a:hover, .single-payment-method a:focus {
    box-shadow: 0 5px 11px rgba(15, 15, 15, 0.175); }
    .single-payment-method a:hover h6, .single-payment-method a:focus h6 {
      color: #100DD1; }

.credit-card-info-wrapper img {
  margin: 0 auto; }

.pay-credit-card-form {
  position: relative;
  z-index: 1; }
  .pay-credit-card-form label {
    font-size: 12px;
    font-weight: 700;
    margin-left: 0.25rem;
    text-transform: uppercase; }
  .pay-credit-card-form small {
    font-size: 11px; }
  .pay-credit-card-form .form-control {
    height: 44px;
    padding-top: 5px;
    padding-bottom: 5px;
    box-shadow: 0 3px 8px rgba(15, 15, 15, 0.1) !important; }

.bank-ac-info .list-group-item {
  font-size: 14px; }

.order-success-wrapper {
  position: relative;
  width: 100%;
  background: #100DD1;
  background: -webkit-linear-gradient(to left, #100DD1, #0862fb);
  background: linear-gradient(to left, #100DD1, #0862fb);
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  padding: 1rem 3rem; }
  .order-success-wrapper i {
    display: inline-block;
    font-size: 3rem;
    margin-bottom: 1rem;
    color: #ffffff; }
  .order-success-wrapper h5 {
    color: #ffffff; }
  .order-success-wrapper p {
    margin-bottom: 0;
    font-size: 13px;
    color: #ffffff; }

.product-slides {
  position: relative;
  z-index: 1;
  margin-bottom: -40px; }
  .product-slides .owl-prev,
  .product-slides .owl-next {
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: transparent;
    text-align: center;
    line-height: 30px;
    z-index: 10;
    top: 50%;
    margin-top: -15px;
    left: 15px;
    font-size: 20px;
    color: #100DD1; }
    .product-slides .owl-prev.disabled,
    .product-slides .owl-next.disabled {
      opacity: 0;
      visibility: hidden; }
  .product-slides .owl-next {
    left: auto;
    right: 15px; }
  .product-slides .owl-dots {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 1rem;
    z-index: 10; }
    .product-slides .owl-dots .owl-dot {
      -webkit-transition-duration: 300ms;
      -o-transition-duration: 300ms;
      transition-duration: 300ms;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 12px;
      flex: 0 0 12px;
      width: 12px;
      max-width: 12px;
      height: 2px;
      background-color: #ebebeb;
      margin: 0 5px; }
      .product-slides .owl-dots .owl-dot.active {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 24px;
        flex: 0 0 24px;
        width: 24px;
        max-width: 24px;
        background-color: #ffaf00; }

.product-title-meta-data {
  border-top-left-radius: 3rem;
  padding-left: 1rem;
  padding-top: 1.5rem !important; }
  .product-title-meta-data::after {
    position: absolute;
    content: "";
    background-image: url(img/core-img/curve.png);
    top: -121px;
    width: 142px;
    height: 121px;
    background-repeat: no-repeat;
    right: 0;
    z-index: auto; }
  .product-title-meta-data .p-wishlist-share {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 80px;
    flex: 0 0 80px;
    width: 80px;
    max-width: 80px;
    text-align: right; }

.product-description {
  position: relative;
  z-index: 1; }
  .product-description .sale-price {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0.5rem;
    color: #ea4c62; }
    .product-description .sale-price span {
      margin-left: 0.5rem;
      color: #747794;
      text-decoration: line-through; }
  .product-description .ratings i {
    color: #ffaf00;
    font-size: 14px;
    margin-right: 3px; }
  .product-description .total-result-of-ratings span:first-child {
    background-color: #00b894;
    padding: 3px 6px;
    color: #ffffff;
    display: inline-block;
    border-radius: .25rem;
    font-size: 12px;
    margin-right: 0.25rem;
    font-weight: 700; }
  .product-description .total-result-of-ratings span:last-child {
    color: #00b894;
    font-size: 12px;
    font-weight: 700; }

.cart-form {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .cart-form .form-control {
    max-width: 60px;
    height: 34.4px;
    margin-right: 0.5rem;
    text-align: center; }

.p-wishlist-share a {
  display: inline-block;
  color: #ea4c62;
  font-size: 20px;
  margin-left: 0.5rem; }
  .p-wishlist-share a:last-child {
    color: #100DD1; }

.choose-color-radio .custom-control-label::before {
  width: 1.25rem;
  height: 1.25rem;
  border: 0;
  background-color: #100DD1; }

.choose-color-radio .custom-control-label.yellow::before {
  background-color: #ffaf00; }

.choose-color-radio .custom-control-label.green::before {
  background-color: #00b894; }

.choose-color-radio .custom-control-label.purple::before {
  background-color: #ea4c62; }

.choose-color-radio .custom-control-label::after {
  width: 1.25rem;
  height: 1.25rem; }

.choose-color-radio .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
  border-color: transparent; }

.choose-color-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #100DD1;
  background-color: #100DD1; }

.choose-color-radio .custom-control-input:checked ~ .custom-control-label.yellow::before {
  background-color: #ffaf00; }

.choose-color-radio .custom-control-input:checked ~ .custom-control-label.green::before {
  background-color: #00b894; }

.choose-color-radio .custom-control-input:checked ~ .custom-control-label.purple::before {
  background-color: #ea4c62; }

.choose-size-radio {
  position: relative;
  z-index: 1;
  text-align: right; }
  .choose-size-radio li a {
    width: 24px;
    height: 24px;
    background-color: #ffffff;
    text-align: center;
    margin-left: .5rem;
    border-radius: .25rem;
    font-size: 12px;
    line-height: 24px;
    color: #747794;
    display: block;
    box-shadow: 0 1px 2px 0 rgba(15, 15, 15, 0.175); }
    .choose-size-radio li a:hover, .choose-size-radio li a:focus {
      color: #ffffff;
      background-color: #ea4c62; }
  .choose-size-radio li.active a {
    color: #ffffff;
    background-color: #ea4c62; }

.p-specification {
  position: relative;
  z-index: 1; }
  .p-specification li {
    font-size: 14px; }

.single-user-review {
  position: relative;
  z-index: 1;
  border-bottom: 2px dashed #ebebeb;
  margin-bottom: 1rem;
  padding-bottom: 1rem; }
  .single-user-review:last-child {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0; }
  .single-user-review .user-thumbnail {
    margin-top: 0.5rem;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 40px;
    flex: 0 0 40px;
    width: 40px;
    max-width: 40px;
    margin-right: .5rem; }
    .single-user-review .user-thumbnail img {
      border-radius: 50%; }
  .single-user-review .rating {
    font-size: 12px;
    color: #ffaf00; }
  .single-user-review .name-date {
    display: block;
    font-size: 11px; }

.ratings-submit-form {
  position: relative;
  z-index: 1; }
  .ratings-submit-form textarea.form-control {
    height: 80px;
    font-size: 14px; }
  .ratings-submit-form .stars {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAABaCAYAAACv+ebYAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNXG14zYAAAAWdEVYdENyZWF0aW9uIFRpbWUAMDcvMDMvMTNJ3Rb7AAACnklEQVRoge2XwW3bMBSGPxa9NxtIGzTAW8DdRL7o3A0qb+BrdNIm9QAm0G7gbJBMwB5MoVJNUSRFIXGqHwhkmXr68hOPNH9ljOEt9OlNqBs4RlrrSmtdpdZ/Ti0EGnvtUoqTHFunBVCkuk6d6mbi83rggdteSa5THDeB3+UDO9z2inatXFum1roESuAReAB29vp15n2/gRfgZK+/gIuIXLxgrfUO+Bnzn0fom4ic+pvRVNuB/QrQ/RB6A7bwLjN8b985krO5MsKd0ElwJvgk1AteCPdCYWI5/SutddQxRUTU3DOzG4hd01EKqQnZuaLBITUh4F0CeLYm5CDw6PjuFTjaz9+BLwE1I8VO9StwAEoRaUSkseMHO+aqcWq2qwcdfQCOIvIy8dwDV/c/YL6zvWDbnQ3QuH5hltQEreM1dH/n6g28gT8eWLVUqqVKrb+vtGidFkCR6vp+0uLAba8k1/eRFh1ue0W7dv4sqpaSjGnR1Fy8YNWyY8W0aGpO/c1oqu3AKmlxCL0BW3iXGb637xzJ2VwZ4U7oJDgTfBLqBS+Ee6EQeMpULVFHUVOzPC3aNR2lkJotLbr0vtKiqWlMTcNaaXHQ0QfgaGqcaVG1jNLibGcbYyb/eDIlT6bjyZS+51JqtrS4gTfw/wzWqkKrKrU8fQPR6gKAmDKlPM3x1WkBFKmu0xxf3fZR5jnFdbzjv257JbmOdzx22yvadZzjW7e9ol27HWtVkjEtIubiB2u1Y8W0iJhTfzOe6uvAKmlxCL0FX+FdZvjevnMkd3Plgzuh0+A88EmoH7wM7oVC6AaiVdwuI2Z5WrRrOk4BNVtadOl9pUXENIhpWCstDjr6ABwR40yLaDVKi7Od7U1/Z0pzpjNngtNiaM2WFj8++A+motm0NTqjmwAAAABJRU5ErkJggg==") repeat-x 0 0;
    width: 150px; }
    .ratings-submit-form .stars:before, .ratings-submit-form .stars:after {
      display: table;
      content: ""; }
    .ratings-submit-form .stars:after {
      clear: both; }
    .ratings-submit-form .stars input[type="radio"] {
      position: absolute;
      opacity: 0; }
      .ratings-submit-form .stars input[type="radio"].star-5:checked ~ span {
        width: 100%; }
      .ratings-submit-form .stars input[type="radio"].star-4:checked ~ span {
        width: 80%; }
      .ratings-submit-form .stars input[type="radio"].star-3:checked ~ span {
        width: 60%; }
      .ratings-submit-form .stars input[type="radio"].star-2:checked ~ span {
        width: 40%; }
      .ratings-submit-form .stars input[type="radio"].star-1:checked ~ span {
        width: 20%; }
    .ratings-submit-form .stars label {
      display: block;
      width: 30px;
      height: 30px;
      margin: 0 !important;
      padding: 0 !important;
      text-indent: -99999rem;
      float: left;
      position: relative;
      z-index: 10;
      background: transparent !important;
      cursor: pointer; }
      .ratings-submit-form .stars label:hover ~ span {
        background-position: 0 -30px; }
      .ratings-submit-form .stars label.star-5:hover ~ span {
        width: 100% !important; }
      .ratings-submit-form .stars label.star-4:hover ~ span {
        width: 80% !important; }
      .ratings-submit-form .stars label.star-3:hover ~ span {
        width: 60% !important; }
      .ratings-submit-form .stars label.star-2:hover ~ span {
        width: 40% !important; }
      .ratings-submit-form .stars label.star-2:hover ~ span {
        width: 20% !important; }
    .ratings-submit-form .stars span {
      display: block;
      width: 0;
      position: relative;
      top: 0;
      left: 0;
      height: 30px;
      background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAABaCAYAAACv+ebYAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNXG14zYAAAAWdEVYdENyZWF0aW9uIFRpbWUAMDcvMDMvMTNJ3Rb7AAACnklEQVRoge2XwW3bMBSGPxa9NxtIGzTAW8DdRL7o3A0qb+BrdNIm9QAm0G7gbJBMwB5MoVJNUSRFIXGqHwhkmXr68hOPNH9ljOEt9OlNqBs4RlrrSmtdpdZ/Ti0EGnvtUoqTHFunBVCkuk6d6mbi83rggdteSa5THDeB3+UDO9z2inatXFum1roESuAReAB29vp15n2/gRfgZK+/gIuIXLxgrfUO+Bnzn0fom4ic+pvRVNuB/QrQ/RB6A7bwLjN8b985krO5MsKd0ElwJvgk1AteCPdCYWI5/SutddQxRUTU3DOzG4hd01EKqQnZuaLBITUh4F0CeLYm5CDw6PjuFTjaz9+BLwE1I8VO9StwAEoRaUSkseMHO+aqcWq2qwcdfQCOIvIy8dwDV/c/YL6zvWDbnQ3QuH5hltQEreM1dH/n6g28gT8eWLVUqqVKrb+vtGidFkCR6vp+0uLAba8k1/eRFh1ue0W7dv4sqpaSjGnR1Fy8YNWyY8W0aGpO/c1oqu3AKmlxCL0BW3iXGb637xzJ2VwZ4U7oJDgTfBLqBS+Ee6EQeMpULVFHUVOzPC3aNR2lkJotLbr0vtKiqWlMTcNaaXHQ0QfgaGqcaVG1jNLibGcbYyb/eDIlT6bjyZS+51JqtrS4gTfw/wzWqkKrKrU8fQPR6gKAmDKlPM3x1WkBFKmu0xxf3fZR5jnFdbzjv257JbmOdzx22yvadZzjW7e9ol27HWtVkjEtIubiB2u1Y8W0iJhTfzOe6uvAKmlxCL0FX+FdZvjevnMkd3Plgzuh0+A88EmoH7wM7oVC6AaiVdwuI2Z5WrRrOk4BNVtadOl9pUXENIhpWCstDjr6ABwR40yLaDVKi7Od7U1/Z0pzpjNngtNiaM2WFj8++A+motm0NTqjmwAAAABJRU5ErkJggg==") repeat-x 0 -60px;
      -webkit-transition: width 0.5s;
      -o-transition: width 0.5s;
      transition: width 0.5s; }

.add2cart-notification {
  position: fixed;
  bottom: 58px;
  width: 100%;
  height: 30px;
  background-color: #00b894;
  z-index: 1000;
  color: #ffffff;
  text-align: center;
  font-size: 12px;
  line-height: 30px;
  font-weight: 700; }

.footer-nav-area {
  -webkit-transition-duration: 500ms;
  -o-transition-duration: 500ms;
  transition-duration: 500ms;
  background-color: #ffffff;
  width: 100%;
  height: 58px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  box-shadow: 0 -2px 12px rgba(15, 15, 15, 0.175); }
  .footer-nav-area.footer-out {
    bottom: -100px; }

.suha-footer-nav ul {
  width: 100%; }
  .suha-footer-nav ul li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
    width: 20%;
    position: relative;
    z-index: 1; }
    .suha-footer-nav ul li a {
      display: block;
      font-size: 12px;
      text-align: center;
      font-weight: 700;
      text-transform: capitalize;
      line-height: 1;
      color: #747794; }
      .suha-footer-nav ul li a i {
        display: block;
        font-size: 18px;
        margin-bottom: 4px; }
      .suha-footer-nav ul li a:hover, .suha-footer-nav ul li a:focus {
        color: #100DD1; }
      .suha-footer-nav ul li a img {
        max-height: 1.5rem;
        margin: 0 auto .25rem;
        display: block; }
    .suha-footer-nav ul li.active a {
      color: #100DD1; }

/* CTA Area */
.cta-area {
  position: relative;
  z-index: 1; }
  .cta-area .cta-text {
    position: relative;
    z-index: 1;
    border-radius: 0.5rem;
    background-size: cover; }
    .cta-area .cta-text p,
    .cta-area .cta-text h4 {
      color: #ffffff; }
    .cta-area .cta-text::after {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      content: "";
      background: #100DD1;
      background: -webkit-linear-gradient(to left, #100DD1, #0862fb);
      background: linear-gradient(to left, #100DD1, #0862fb);
      z-index: -10;
      border-radius: 0.5rem;
      opacity: .75; }

/* Register CSS */
.login-wrapper {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: #100DD1;
  background: -webkit-linear-gradient(to left, #100DD1, #0862fb);
  background: linear-gradient(to left, #100DD1, #0862fb);
  z-index: 10;
  overflow-y: auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
  overflow-x: hidden; }
  .login-wrapper .background-shape {
    position: absolute;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background-color: #ffffff;
    opacity: 0.1;
    z-index: -1;
    top: -100px;
    right: -60px; }
  .login-wrapper .success-check {
    color: #ffffff;
    margin: 0 auto; }
    .login-wrapper .success-check i {
      font-size: 3rem; }

.register-form {
  position: relative;
  z-index: 1; }
  .register-form .form-group {
    position: relative;
    z-index: 1; }
  .register-form span {
    color: #ffffff;
    font-size: 12px;
    display: block; }
  .register-form form > .form-group > label {
    position: absolute;
    text-align: center;
    z-index: 100;
    border-radius: 50%;
    left: 0;
    font-size: 16px;
    margin-bottom: 0;
    color: #ffffff;
    top: 26px; }
  .register-form .form-control {
    border-radius: 0;
    border: 0;
    color: #ffffff;
    font-size: 13px;
    background-color: transparent;
    height: 40px;
    padding: 8px 8px 8px 24px;
    border-radius: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
    .register-form .form-control::placeholder {
      color: #ffffff;
      opacity: 0.4; }
  .register-form .progress {
    width: 100%;
    height: 5px;
    margin-top: 1rem;
    border-radius: 1rem;
    margin-bottom: 0.25rem; }
  .register-form .password-score {
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    .register-form .password-score span {
      font-size: 18px;
      margin-left: 1rem; }
  .register-form .password-recommendation {
    display: none !important; }
  .register-form #password-recommendation-heading {
    font-weight: 700;
    color: #00b894;
    font-size: 16px; }

.login-meta-data a {
  font-size: 14px;
  color: #ffffff; }
  .login-meta-data a:hover, .login-meta-data a:focus {
    color: #ffaf00; }

.login-meta-data p a {
  color: #ffffff; }
  .login-meta-data p a:hover, .login-meta-data p a:focus {
    color: #ffaf00; }

.go-back-btn {
  -webkit-transition-duration: 400ms;
  -o-transition-duration: 400ms;
  transition-duration: 400ms;
  position: fixed;
  top: 30px;
  right: 30px;
  background-color: #ea4c62;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  color: #ffffff;
  line-height: 42px;
  z-index: 10;
  cursor: pointer; }
  .go-back-btn:hover, .go-back-btn:focus {
    background-color: #100DD1;
    color: #ffffff; }

/* Contact CSS */
.form-control {
  -webkit-transition-duration: 500ms;
  -o-transition-duration: 500ms;
  transition-duration: 500ms;
  border-color: #ebebeb;
  height: 50px;
  padding: 12px 20px;
  font-size: 14px; }
  .form-control:focus {
    box-shadow: none; }

/* Blog CSS */
.blog-card {
  position: relative;
  z-index: 1; }
  .blog-card .post-img {
    position: relative;
    z-index: 1;
    border-radius: 0.5rem; }
    .blog-card .post-img img {
      border-radius: 0.5rem; }
    .blog-card .post-img::after {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      content: "";
      background-color: #020310;
      opacity: 0.3;
      z-index: 1;
      border-radius: 0.5rem; }
  .blog-card .post-bookmark {
    position: absolute;
    z-index: 100;
    color: #ffffff;
    top: .5rem;
    right: .5rem;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: transparent;
    text-align: center; }
    .blog-card .post-bookmark i {
      line-height: 30px; }
    .blog-card .post-bookmark:hover, .blog-card .post-bookmark:focus {
      background-color: rgba(255, 255, 255, 0.2); }
  .blog-card .post-content {
    position: absolute !important;
    width: calc(100% - 1rem);
    bottom: .5rem;
    left: .5rem;
    z-index: 100;
    border-radius: .5rem;
    background-color: #ffffff;
    padding: .75rem;
    overflow: hidden; }
    .blog-card .post-content .bg-shapes .circle1 {
      width: 30px;
      height: 30px;
      position: absolute;
      border-radius: 50%;
      top: -15px;
      right: -15px;
      background-color: transparent;
      border: 1px solid #ebebeb;
      z-index: -1; }
    .blog-card .post-content .bg-shapes .circle2 {
      width: 50px;
      height: 50px;
      position: absolute;
      border-radius: 50%;
      top: -25px;
      right: -25px;
      background-color: transparent;
      border: 1px solid #ebebeb;
      z-index: -1; }
    .blog-card .post-content .bg-shapes .circle3 {
      width: 70px;
      height: 70px;
      position: absolute;
      border-radius: 50%;
      top: -35px;
      right: -35px;
      background-color: transparent;
      border: 1px solid #ebebeb;
      z-index: -1; }
    .blog-card .post-content .bg-shapes .circle4 {
      width: 90px;
      height: 90px;
      position: absolute;
      border-radius: 50%;
      top: -45px;
      right: -45px;
      background-color: transparent;
      border: 1px solid #ebebeb;
      z-index: -1; }
    .blog-card .post-content .post-catagory {
      font-size: 11px;
      text-transform: uppercase;
      font-weight: 700;
      color: #ea4c62;
      margin-bottom: 0.125rem;
      letter-spacing: .5px; }
    .blog-card .post-content .post-title {
      font-size: 14px;
      font-weight: 700;
      line-height: 1.3;
      color: #020310;
      margin-bottom: .5rem;
      overflow: hidden;
      height: 38px; }
      .blog-card .post-content .post-title:hover, .blog-card .post-content .post-title:focus {
        color: #100DD1; }
    .blog-card .post-content .post-meta {
      position: relative;
      z-index: 1; }
      .blog-card .post-content .post-meta a {
        font-size: 10px;
        line-height: 1;
        color: #747794; }
        .blog-card .post-content .post-meta a:hover, .blog-card .post-content .post-meta a:focus {
          color: #100DD1; }
        .blog-card .post-content .post-meta a i {
          margin-right: 0.25rem; }
      .blog-card .post-content .post-meta span {
        font-size: 10px;
        line-height: 1;
        color: #747794; }
        .blog-card .post-content .post-meta span i {
          margin-right: 0.25rem; }
  .blog-card.list-card .post-content {
    width: calc(50% - 1rem);
    bottom: .75rem;
    left: .75rem; }
  .blog-card.list-card .post-bookmark {
    top: .75rem;
    right: .75rem; }
  .blog-card.list-card .read-more-btn {
    position: absolute;
    min-width: 100px;
    right: .75rem;
    bottom: .75rem;
    z-index: 99; }

.blog-catagory-card {
  position: relative;
  z-index: 1; }
  .blog-catagory-card a {
    display: block; }
    .blog-catagory-card a i {
      color: #100DD1;
      font-size: 2rem;
      margin-bottom: 1rem;
      display: block; }
    .blog-catagory-card a span {
      -webkit-transition-duration: 500ms;
      -o-transition-duration: 500ms;
      transition-duration: 500ms;
      color: #747794;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: .5px; }
    .blog-catagory-card a:hover span, .blog-catagory-card a:focus span {
      color: #100DD1; }

.blog-details-post-thumbnail {
  position: relative;
  z-index: 1;
  margin-bottom: -40px; }
  .blog-details-post-thumbnail .post-bookmark {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #ea4c62;
    text-align: center;
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 100;
    color: #ffffff; }
    .blog-details-post-thumbnail .post-bookmark i {
      line-height: 35px; }
    .blog-details-post-thumbnail .post-bookmark:hover, .blog-details-post-thumbnail .post-bookmark:focus {
      background-color: #100DD1;
      color: #ffffff; }

.product-title-meta-data {
  position: relative;
  z-index: 1; }
  .product-title-meta-data .post-catagory {
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: .5px;
    color: #ea4c62;
    font-weight: 700; }

.post-meta-data a {
  color: #747794;
  font-size: 12px; }
  .post-meta-data a img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 0.25rem; }
  .post-meta-data a span {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    padding: 3px 8px;
    border: 1px solid #ebebeb;
    border-radius: .25rem;
    margin-left: 0.5rem; }
  .post-meta-data a:hover span, .post-meta-data a:focus span {
    border-color: #020310;
    color: #020310; }

.post-meta-data span {
  color: #747794;
  font-size: 12px; }
  .post-meta-data span i {
    margin-right: 0.25rem; }

.post-content p:last-child {
  margin-bottom: 0; }

/* Night Mode */
#previewPage[data-theme="dark"] {
  background-color: #e7eaf5; }
  #previewPage[data-theme="dark"] body {
    background-color: #e7eaf5; }
  #previewPage[data-theme="dark"] .preview-iframe-wrapper .preview-hero-area .preview-content-wrapper .demo-title {
    color: #020310; }
  #previewPage[data-theme="dark"] h1,
  #previewPage[data-theme="dark"] h2,
  #previewPage[data-theme="dark"] h3,
  #previewPage[data-theme="dark"] h4,
  #previewPage[data-theme="dark"] h5,
  #previewPage[data-theme="dark"] h6 {
    color: #020310; }

[data-theme="dark"] {
  background-color: #0C153B; }
  [data-theme="dark"] body {
    background-color: #0C153B; }
  [data-theme="dark"] a,
  [data-theme="dark"] a:hover,
  [data-theme="dark"] a:focus {
    color: #ffffff; }
  [data-theme="dark"] h1,
  [data-theme="dark"] h2,
  [data-theme="dark"] h3,
  [data-theme="dark"] h4,
  [data-theme="dark"] h5,
  [data-theme="dark"] h6 {
    color: rgba(255, 255, 255, 0.8); }
  [data-theme="dark"] .header-area {
    background-color: #0C153B;
    box-shadow: 0 3px 12px rgba(255, 255, 255, 0.175); }
  [data-theme="dark"] .footer-nav-area {
    background-color: #0C153B;
    box-shadow: 0 -3px 12px rgba(255, 255, 255, 0.175); }
  [data-theme="dark"] .card {
    border-color: #242644;
    background-color: #242644; }
  [data-theme="dark"] .suha-footer-nav ul li a {
    color: rgba(255, 255, 255, 0.8); }
  [data-theme="dark"] .suha-footer-nav ul li.active a {
    color: #ffaf00; }
  [data-theme="dark"] .suha-footer-nav ul li a:hover,
  [data-theme="dark"] .suha-footer-nav ul li a:focus {
    color: #ffaf00; }
  [data-theme="dark"] .top-search-form form .form-control {
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.125); }
  [data-theme="dark"] .btn-warning:hover, [data-theme="dark"] .btn-warning:focus {
    color: #ffffff;
    background-color: #282958;
    border-color: #282958; }
  [data-theme="dark"] .btn-danger:hover, [data-theme="dark"] .btn-danger:focus {
    background-color: #282958;
    border-color: #282958; }
  [data-theme="dark"] .btn-primary:hover, [data-theme="dark"] .btn-primary:focus {
    background-color: #282958;
    border-color: #282958; }
  [data-theme="dark"] .btn-success:hover, [data-theme="dark"] .btn-success:focus {
    background-color: #282958;
    border-color: #282958; }
  [data-theme="dark"] .btn-info:hover, [data-theme="dark"] .btn-info:focus {
    background-color: #282958;
    border-color: #282958; }
  [data-theme="dark"] .catagory-card span {
    color: #ffffff; }
  [data-theme="dark"] .catagory-card:hover span,
  [data-theme="dark"] .catagory-card:focus span {
    color: #ffaf00; }
  [data-theme="dark"] .flash-sale-card .product-title {
    color: #ffffff; }
  [data-theme="dark"] .flash-sale-card:hover .product-title,
  [data-theme="dark"] .flash-sale-card:focus .product-title {
    color: #ffaf00; }
  [data-theme="dark"] .flash-sale-card .sale-price {
    color: #ffffff; }
  [data-theme="dark"] .flash-sale-card .progress-title {
    color: rgba(255, 255, 255, 0.7); }
  [data-theme="dark"] .top-product-card .product-title {
    color: #ffffff; }
  [data-theme="dark"] .top-product-card:hover .product-title,
  [data-theme="dark"] .top-product-card:focus .product-title {
    color: #ffaf00; }
  [data-theme="dark"] .top-product-card .btn:hover, [data-theme="dark"] .top-product-card .btn:focus {
    background-color: #ea4c62;
    border-color: #ea4c62; }
  [data-theme="dark"] .weekly-product-card .product-description .product-title {
    color: #ffffff; }
  [data-theme="dark"] .weekly-product-card:hover .product-title,
  [data-theme="dark"] .weekly-product-card:focus .product-title {
    color: #ffaf00; }
  [data-theme="dark"] .weekly-product-card .product-description .sale-price {
    color: #ffaf00; }
  [data-theme="dark"] .page-nav li a {
    background-color: #332858;
    color: #ffffff; }
  [data-theme="dark"] .page-nav li a:hover,
  [data-theme="dark"] .page-nav li a:focus {
    color: #ffaf00; }
  [data-theme="dark"] .cart-table table tbody tr {
    background-color: transparent; }
  [data-theme="dark"] .cart-table table a {
    color: #ffffff; }
  [data-theme="dark"] .cart-table table a:hover,
  [data-theme="dark"] .cart-table table a:focus {
    color: #ffaf00; }
  [data-theme="dark"] .cart-table img {
    border-color: #ffffff; }
  [data-theme="dark"] .cart-table .qty-text {
    border-color: #ffffff; }
  [data-theme="dark"] .back-button a {
    color: #ffffff; }
  [data-theme="dark"] .back-button a:hover,
  [data-theme="dark"] .back-button a:focus {
    color: #ffaf00; }
  [data-theme="dark"] .filter-option {
    color: #ffaf00; }
  [data-theme="dark"] .catagory-sidebar-area,
  [data-theme="dark"] .type-text-form {
    background-color: #0C153B; }
  [data-theme="dark"] .type-text-form {
    box-shadow: 0 -3px 12px rgba(255, 255, 255, 0.175); }
  [data-theme="dark"] .type-text-form .form-control,
  [data-theme="dark"] .type-text-form .file-upload span,
  [data-theme="dark"] .type-text-form button[type="submit"] {
    background-color: #0C153B; }
  [data-theme="dark"] .type-text-form button[type="submit"] {
    color: #ffffff;
    outline: none; }
  [data-theme="dark"] .user-info-card {
    background-color: #ffaf00;
    border-color: #ffaf00; }
    [data-theme="dark"] .user-info-card h5 {
      color: #ffffff; }
  [data-theme="dark"] .user-data-card .title {
    color: #ffffff; }
  [data-theme="dark"] .cart-table table tbody tr {
    border-color: rgba(255, 255, 255, 0.2); }
  [data-theme="dark"] .pay-credit-card-form small a {
    color: #ffffff; }
    [data-theme="dark"] .pay-credit-card-form small a:hover, [data-theme="dark"] .pay-credit-card-form small a:focus {
      color: #ffaf00; }
  [data-theme="dark"] .single-payment-method a {
    background-color: #ea4c62; }
    [data-theme="dark"] .single-payment-method a h6 {
      color: #ffffff; }
    [data-theme="dark"] .single-payment-method a:hover h6, [data-theme="dark"] .single-payment-method a:focus h6 {
      color: #100DD1; }
  [data-theme="dark"] .billing-information-title-card {
    border-color: transparent; }
  [data-theme="dark"] .shipping-method-choose-title-card {
    border-color: transparent; }
  [data-theme="dark"] .language-area-wrapper input[type="radio"]:checked ~ label,
  [data-theme="dark"] .shipping-method-choose input[type="radio"]:checked ~ label {
    color: #ffffff;
    background-color: rgba(16, 13, 209, 0.7); }
  [data-theme="dark"] .language-area-wrapper input[type="radio"]:checked ~ .check::before,
  [data-theme="dark"] .shipping-method-choose input[type="radio"]:checked ~ .check::before {
    background: #ffffff; }
  [data-theme="dark"] .language-area-wrapper input[type="radio"]:checked ~ .check,
  [data-theme="dark"] .shipping-method-choose input[type="radio"]:checked ~ .check {
    border-color: #ffffff; }
  [data-theme="dark"] .single-order-status .order-status {
    color: #ffffff; }
  [data-theme="dark"] .single-order-status .order-icon {
    color: #ffaf00; }
  [data-theme="dark"] .list-group-item {
    background-color: #242644;
    border-color: #242644; }
  [data-theme="dark"] .notification-area .list-group-item h6 {
    color: #ffffff; }
  [data-theme="dark"] .notification-area .list-group-item:hover h6,
  [data-theme="dark"] .notification-area .list-group-item:focus h6 {
    color: #ffaf00; }
  [data-theme="dark"] .notification-area .list-group-item.readed {
    background-color: #0C153B; }
  [data-theme="dark"] .single-settings .button.r {
    border-color: rgba(255, 255, 255, 0.15); }
  [data-theme="dark"] .single-settings .data-content a:hover, [data-theme="dark"] .single-settings .data-content a:focus {
    color: #ffaf00; }
  [data-theme="dark"] .layout-options a {
    background-color: #242644; }
  [data-theme="dark"] .layout-options a:hover,
  [data-theme="dark"] .layout-options a:focus,
  [data-theme="dark"] .layout-options a.active {
    background-color: #ea4c62; }
  [data-theme="dark"] .post-content,
  [data-theme="dark"] .selection-panel.bg-white,
  [data-theme="dark"] .product-title-meta-data.bg-white,
  [data-theme="dark"] .cart-form-wrapper.bg-white,
  [data-theme="dark"] .p-specification.bg-white,
  [data-theme="dark"] .rating-and-review-wrapper.bg-white,
  [data-theme="dark"] .ratings-submit-form.bg-white {
    background-color: #242644 !important; }
  [data-theme="dark"] .p-wishlist-share a {
    color: #ea4c62; }
  [data-theme="dark"] .p-wishlist-share a:last-child {
    color: #ffaf00; }
  [data-theme="dark"] .single-user-review {
    border-color: rgba(255, 255, 255, 0.2); }
  [data-theme="dark"] .accordian-card .accordian-header button {
    color: #ffaf00; }
  [data-theme="dark"] .accordian-card .accordian-header button.collapsed > span {
    color: #ffffff; }
  [data-theme="dark"] .faq-search-form input {
    border-color: transparent; }
  [data-theme="dark"] .top-product-card .delete-btn,
  [data-theme="dark"] .weekly-product-card .product-thumbnail-side .delete-btn {
    color: #020310; }
  [data-theme="dark"] .product-title-meta-data::after {
    background-image: url(img/core-img/curve2.png); }
  [data-theme="dark"] .top-product-card .wishlist-btn {
    color: #ea4c62; }
  [data-theme="dark"] .catagory-card i {
    color: #ffaf00; }
  [data-theme="dark"] .blog-catagory-card a i {
    color: #ffaf00; }
  [data-theme="dark"] .blog-catagory-card a span {
    color: #ffffff; }
    [data-theme="dark"] .blog-catagory-card a span:hover, [data-theme="dark"] .blog-catagory-card a span:focus {
      color: #ffaf00; }
  [data-theme="dark"] .post-meta-data a span {
    border-color: rgba(255, 255, 255, 0.7);
    color: rgba(255, 255, 255, 0.7); }
    [data-theme="dark"] .post-meta-data a span:hover, [data-theme="dark"] .post-meta-data a span:focus {
      border-color: #ffaf00;
      color: #ffaf00; }
  [data-theme="dark"] .ratings-submit-form textarea.form-control {
    border-color: rgba(255, 255, 255, 0.2);
    background-color: rgba(255, 255, 255, 0.2);
    color: #ffffff !important; }
  [data-theme="dark"] .blog-card .post-content .post-title {
    color: #ffffff; }
    [data-theme="dark"] .blog-card .post-content .post-title:hover, [data-theme="dark"] .blog-card .post-content .post-title:focus {
      color: #ffaf00; }
  [data-theme="dark"] .blog-card .post-content .post-meta a,
  [data-theme="dark"] .blog-card .post-content .post-meta span {
    color: rgba(255, 255, 255, 0.7); }
    [data-theme="dark"] .blog-card .post-content .post-meta a:hover, [data-theme="dark"] .blog-card .post-content .post-meta a:focus,
    [data-theme="dark"] .blog-card .post-content .post-meta span:hover,
    [data-theme="dark"] .blog-card .post-content .post-meta span:focus {
      color: #ffffff; }
  [data-theme="dark"] .blog-card .post-content .bg-shapes .circle1,
  [data-theme="dark"] .blog-card .post-content .bg-shapes .circle2,
  [data-theme="dark"] .blog-card .post-content .bg-shapes .circle3,
  [data-theme="dark"] .blog-card .post-content .bg-shapes .circle4 {
    border-color: rgba(255, 255, 255, 0.1); }
